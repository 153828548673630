<template>
  <div>
    <!-- modal -->
    <b-modal
      v-model="dialogS"
      size="lg"
      title="Detail"
      ok-only
      ok-title="Exit"
      no-stacking
    >
      <b-row>
        <b-col cols="6">
          <b-form-group label="Mac">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="mac"
                v-model="mac"
                type="text"
                placeholder="mac"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Code name">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="codename"
                v-model="codename"
                type="text"
                placeholder="codename"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Description">
            <b-input-group class="input-group-merge">
              <b-form-textarea
                id="description"
                v-model="description"
                type="text"
                placeholder="description"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Tag">
            <b-input-group class="input-group-merge">
              <b-form-tags
                v-model="tag"
                input-id="tags-basic"
                class="mb-2"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col cols="6">
          <b-form-group label="Latitude">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="latitude"
                v-model="latitude"
                disabled
                placeholder="latitude"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Longitude">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="longitude"
                v-model="longitude"
                placeholder="longitude"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Board">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="board"
                v-model="board"
                placeholder="board"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Uptime">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="uptime"
                v-model="uptime"
                placeholder="uptime"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Freemem">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="freemem"
                v-model="freemem"
                placeholder="freemem"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Mem">
            <b-input-group class="input-group-merge">
              <b-form-input id="mem" v-model="mem" placeholder="mem" disabled />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="CPU">
            <b-input-group class="input-group-merge">
              <b-form-input id="cpu" v-model="cpu" placeholder="cpu" disabled />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="CPU core">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="cpucore"
                v-model="cpucore"
                placeholder="cpucore"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="CPU freq">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="cpufreq"
                v-model="cpufreq"
                placeholder="cpufreq"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="CPU load">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="cpuload"
                v-model="cpuload"
                placeholder="cpuload"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Arch">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="arch"
                v-model="arch"
                placeholder="arch"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col cols="6">
          <b-form-group label="Version">
            <b-input-group class="input-group-merge">
              <b-form-input
                id="version"
                v-model="version"
                placeholder="version"
                disabled
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <!-- <b-col cols="6">
          <b-form-group label="Lastseen">
            <b-input-group class="input-group-merge">
              <flat-pickr
                id="lastseen"
                v-model="lastseen"
                placeholder="lastseen"
                class="form-control"
                :config="{
                  enableTime: true,
                  dateFormat: 'Y-m-d H:i',
                }"
              />
            </b-input-group>
          </b-form-group>
        </b-col> -->
        <!-- <b-col cols="6">
          <b-form-group label="Status">
            <b-input-group class="input-group-merge">
              <b-input-group-prepend is-text>
                <feather-icon icon="LockIcon" />
              </b-input-group-prepend>
              <b-form-input id="status" v-model="status" placeholder="status" />
            </b-input-group>
          </b-form-group>
        </b-col> -->
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BLink,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BFormTextarea,
  BCardCode,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
  BFormCheckbox,
  BFormTag,
  BFormTags,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BLink,
    BListGroupItem,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BFormTextarea,
    BCardCode,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    BFormCheckbox,

    BFormTag,
    BFormTags,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      tag: [],
      items: [],
      dialogS: false,
      venue_id: null,
      mac: null,
      codename: null,
      description: null,
      type: "Gateway",
      latitude: null,
      longitude: null,
      adddate: null,
      board: null,
      uptime: null,
      freemem: null,
      mem: null,
      cpu: null,
      cpucore: null,
      cpufreq: null,
      cpuload: null,
      arch: null,
      version: null,
      lastseen: null,
      status: null,
    };
  },
  mounted() {},
  created() {},
  computed: {},
  methods: {
    async showModaldata(id) {
      console.log("id", id);
      this.tag = [];
      const params = {
        id: id,
      };
      api.post("/hotspotgetid", params).then((response) => {
        console.log("response", response.data);
        for (let item of response.data.data) {
          this.mac = item.mac;
          this.codename = item.codename;
          this.description = item.description;
          this.type = item.type;
          this.latitude = item.latitude;
          this.longitude = item.longitude;
          this.adddate = item.adddate;
          this.board = item.board;
          this.uptime = item.uptime;
          this.freemem = item.freemem;
          this.mem = item.mem;
          this.cpu = item.cpu;
          this.cpucore = item.cpucore;
          this.cpufreq = item.cpufreq;
          this.cpuload = item.cpuload;
          this.arch = item.arch;
          this.version = item.version;
          this.lastseen = item.lastseen;
          this.status = item.status;
          if (item.tag) {
          const tagArray = item.tag.split(",");
          console.log("tagArray :>> ", tagArray);
          for (let i = 0; i < tagArray.length; i++) {
            console.log(tagArray[i]);
            this.tag.push(tagArray[i]);
          }}
        }
      });
      this.dialogS = true;
    },
  },
};
</script>