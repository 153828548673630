<template>
  <section class="invoice-add-wrapper">
    <b-button
      v-ripple.400="'rgba(113, 102, 240, 0.15)'"
      v-b-modal.modal-xl
      variant="outline-primary"
    >
      Add Hotspot
    </b-button>

    <b-modal
      v-model="dialog1"
      id="modal-xl"
      ok-only
      ok-title="close"
      centered
      size="lg"
      title="Add Hotspot"
    >
      <b-card-text>
        <b-row class="invoice-add">
          <b-col cols="6" xl="12" md="12">
            <b-form enctype="multipart/form-data" @submit.prevent>
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <b-row ref="row" class="pb-2">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="6">
                            <b-form-group label="Mac">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="mac"
                                  v-model="mac"
                                  type="text"
                                  placeholder="mac"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.mac == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>

                          <b-col cols="6">
                            <b-form-group label="Tag">
                              <b-form-tags
                                v-model="tag"
                                input-id="tags-basic"
                                class="mb-2"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Type">
                              <b-form-select
                                v-model="selected"
                                :options="options"
                                :state="selected === null ? false : true"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="เลือกสถานะการติดตั้ง">
                              <b-form-select
                                v-model="selected2"
                                :options="options2"
                                :state="selected2 === null ? false : true"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col cols="12">
                            <b-form-group label="Description">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="description"
                                  v-model="description"
                                  type="text"
                                  placeholder="description"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <b-col cols="6">
                            <b-form-group label="Latitude">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="latitude"
                                  v-model="latitude"
                                  placeholder="latitude"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.latitude == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Longitude">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="longitude"
                                  v-model="longitude"
                                  placeholder="longitude"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.longitude == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>

                          <!-- submit and reset -->
                          <b-col>
                            <br /><br />
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="submit"
                              variant="primary"
                              class="mr-1"
                              @click="save()"
                            >
                              Submit
                            </b-button>
                            <b-button
                              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                              type="reset"
                              variant="outline-secondary"
                              @click="reset()"
                            >
                              Reset
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  VBModal,
  BFormDatepicker,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
  BFormCheckbox,
  BFormTag,
  BFormTags,
  BFormSelect,
} from "bootstrap-vue";
import Swal from "sweetalert2";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BListGroupItem,
    BCardTitle,
    VBModal,
    BFormDatepicker,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    BFormCheckbox,
    BFormTag,
    BFormTags,
    BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      dialog1: null,
      venue_id: localStorage.getItem("venue"),
      mac: null,
      latitude: null,
      longitude: null,
      description: null,
      tag: [],
      items: [],
      selected: null,
      selected2: null,
      options: [
        { value: null, text: "เลือกประเภท" },
        { value: "AP", text: "AP" },
        { value: "Gateway", text: "Gateway" },
      ],
      options2: [
        { value: null, text: "เลือกสถานะการติดตั้ง" },
        { value: 'รอติดตั้ง', text: "รอติดตั้ง" },
        { value: 'ติดตั้งแล้ว', text: "ติดตั้งแล้ว" },
      ],
      check: {
        mac: false,
        latitude: false,
        longitude: false,
      },
    };
  },

  methods: {
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
    save() {
      console.log("tag :>> ", this.tag);
      if (this.mac && this.latitude && this.longitude && this.tag && this.selected) {
        Swal.fire({
          title: "ยืนยันการบันทึกข้อมูล",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showLoaderOnConfirm: true,
          preConfirm: () => {},
        }).then((result) => {
          if (result.isConfirmed) {
            this.items = [];
            for (let i = 0; i < this.tag.length; i++) {
              console.log(this.tag[i]);
              this.items.push(this.tag[i]);
            }
            let formData = new FormData();
            formData.append("venue_id", this.venue_id);
            formData.append("type", this.selected);
            formData.append("status_install", this.selected2);
            formData.append("mac", this.mac);
            formData.append("latitude", this.latitude);
            formData.append("longitude", this.longitude);
            formData.append("tag", this.items);
            formData.append("description", this.description);

            api.post("/addhotspotgetid", formData).then((response) => {
              console.log("response", response);
              if (response) {
                Swal.fire({
                  icon: "success",
                  title: "success",
                  showConfirmButton: false,
                  timer: 1500,
                });
                this.dialog1 = false;
                this.refresh();
                this.reset();
              }
            });
          }
        });
      } else {
        if (!this.mac) {
          this.check.mac = true;
          console.log("mac null");
        }
        if (!this.longitude) {
          this.check.longitude = true;
          console.log("longitude null");
        }
        if (!this.latitude) {
          this.check.latitude = true;
          console.log("latitude null");
        }
        this.$swal({
          title: "Please complete the information.",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      }
    },
    reset() {
      this.description = null;
      this.mac = null;
      this.latitude = null;
      this.longitude = null;
      this.tag = [];
      this.check.mac = false;
      this.check.latitude = false;
      this.check.longitude = false;
    },
  },
  setup() {},
};
</script>