<template>
  <b-row>
    <!-- outline color -->
    <b-col cols="12">
      <b-card
        border-variant="primary"
        :title="venue.title"
        bg-variant="transparent"
        class="shadow-none"
      >
      </b-card>
    </b-col>

    <b-col cols="12">
      <table-kitchen-sink />
    </b-col>
  </b-row>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BCardTitle } from "bootstrap-vue";
import api from "@/api";
import TableKitchenSink from "./TableKitchenSink.vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    TableKitchenSink,
  },
  data() {
    return {
      venue: "",
    };
  },
  mounted() {
    const params = {
      venue_id: localStorage.getItem("venue"),
    };
    api
      .post("/partner/getvenue", params)
      .then((response) => {
        this.venue = response.data;
      })
      .catch((error) => {
        // router.push('/pages/not-authorized');
      });
  },
  methods
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
