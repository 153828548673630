<template>
  <div>
    <!-- modal -->
    <b-modal
      v-model="dialogE"
      size="lg"
      title="Update"
      ok-only
      ok-title="Exit"
      no-stacking
    >
      <b-card-text>
        <b-row class="invoice-add">
          <b-col cols="6" xl="12" md="12">
            <b-form enctype="multipart/form-data" @submit.prevent>
              <b-card no-body class="invoice-preview-card">
                <b-card-body class="invoice-padding form-item-section">
                  <div ref="form" class="repeater-form">
                    <b-row ref="row" class="pb-2">
                      <b-form @submit.prevent>
                        <b-row>
                          <b-col cols="6">
                            <b-form-group label="Mac">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="mac"
                                  v-model="mac"
                                  type="text"
                                  placeholder="mac"
                                />
                              </b-input-group>
                              <b-alert
                                v-if="check.mac == true"
                                variant="danger"
                                show
                              >
                                <span>Please Enter</span>
                              </b-alert>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Tag">
                              <b-form-tags
                                v-model="tag"
                                input-id="tags-basic"
                                class="mb-2"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Type">
                              <b-form-select
                                v-model="selected"
                                :options="options"
                                :state="selected === null ? false : true"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="เลือกสถานะการติดตั้ง">
                              <b-form-select
                                v-model="selected2"
                                :options="options2"
                                :state="selected2 === null ? false : true"
                              />
                            </b-form-group>
                          </b-col>

                          <b-col cols="12">
                            <b-form-group label="Description">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="description"
                                  v-model="description"
                                  type="text"
                                  placeholder="description"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <b-col cols="6">
                            <b-form-group label="Latitude">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="latitude"
                                  v-model="latitude"
                                  placeholder="latitude"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="6">
                            <b-form-group label="Longitude">
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  id="longitude"
                                  v-model="longitude"
                                  placeholder="longitude"
                                />
                              </b-input-group>
                            </b-form-group>
                          </b-col>

                          <!-- submit and reset -->
                          <b-col>
                            <br /><br />
                            <b-button
                              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                              type="submit"
                              variant="primary"
                              class="mr-1"
                              @click="Update()"
                            >
                              Update
                            </b-button>
                          </b-col>
                        </b-row>
                      </b-form>
                    </b-row>
                  </div>
                </b-card-body>
              </b-card>
            </b-form>
          </b-col>
        </b-row>
      </b-card-text>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BCardBody,
  BButton,
  BCardText,
  BListGroupItem,
  BCardTitle,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupPrepend,
  BCardCode,
  VBModal,
  BCarousel,
  BCarouselSlide,
  BContainer,
  BFormText,
  BAlert,
  BFormTag,
  BFormTags,
  BFormSelect,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import "animate.css";
import api from "@/api";
import Swal from "sweetalert2";
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardBody,
    BButton,
    BCardText,
    BListGroupItem,
    BCardTitle,
    BForm,
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
    BCardCode,
    VBModal,
    BCarousel,
    BCarouselSlide,
    BContainer,
    BFormText,
    BAlert,
    BFormTag,
    BFormTags,
    BFormSelect,
  },
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  data() {
    return {
      id: null,
      tag: [],
      items: [],
      dialogE: false,
      venue_id: null,
      mac: null,
      latitude: null,
      longitude: null,
      description: null,
      selected: null,
      selected2: null,
      options: [
        { value: null, text: "เลือกประเภท" },
        { value: "AP", text: "AP" },
        { value: "Gateway", text: "Gateway" },
      ],
      options2: [
        { value: null, text: "เลือกสถานะการติดตั้ง" },
        { value: 'รอติดตั้ง', text: "รอติดตั้ง" },
        { value: 'ติดตั้งแล้ว', text: "ติดตั้งแล้ว" },
      ],
      check: {
        mac: false,
        latitude: false,
        longitude: false,
      },
    };
  },
  mounted() {},
  created() {},
  computed: {},
  methods: {
    Update() {
      if (this.mac && this.latitude && this.longitude && this.selected) {
        Swal.fire({
          title: "ยืนยันการบันทึกข้อมูล",
          text: "",
          icon: "question",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          confirmButtonText: "ยืนยัน",
          cancelButtonText: "ยกเลิก",
          showLoaderOnConfirm: true,
          preConfirm: () => {},
        }).then((result) => {
          if (result.isConfirmed) {
            this.items = [];
            for (let i = 0; i < this.tag.length; i++) {
              console.log(this.tag[i]);
              this.items.push(this.tag[i]);
            }
            let formData = new FormData();
            formData.append("id", this.id);
            formData.append("mac", this.mac);
            formData.append("type", this.selected);
            formData.append("status_install", this.selected2);
            formData.append("latitude", this.latitude);
            formData.append("longitude", this.longitude);
            formData.append("tag", this.items);
            formData.append("description", this.description);

            api.post("/hotspotgetidupdat", formData).then((response) => {
              console.log("response", response);
              this.refresh();
              if (response) {
                Swal.fire({
                  icon: "success",
                  title: "success",
                  showConfirmButton: false,
                  timer: 3000,
                });
                this.dialogE = false;
              }
            });
          }
        });
      } else {
        if (!this.mac) {
          this.check.mac = true;
          console.log("mac null");
        }
        if (!this.longitude) {
          this.check.longitude = true;
          console.log("codename null");
        }
        if (!this.latitude) {
          this.check.latitude = true;
          console.log("latitude null");
        }
        this.$swal({
          title: "Please complete the information.",
          customClass: {
            confirmButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__bounceIn",
          },
          buttonsStyling: false,
        });
      }
    },
    async showModaledit(id) {
      this.id = id;
      this.tag = [];
      this.check.mac = false;
      this.check.latitude = false;
      this.check.longitude = false;
      console.log("id", id);
      const params = {
        id: id,
      };
      api.post("/hotspotgetid", params).then((response) => {
        console.log("hotspotgetid", response.data);
        for (let item of response.data.data) {
          this.mac = item.mac;
          this.codename = item.codename;
          this.latitude = item.latitude;
          this.longitude = item.longitude;
          this.selected = item.type;
          this.selected2 = item.status_install;
          this.description = item.description;
          if (item.tag) {
            const tagArray = item.tag.split(",");
            console.log("tagArray :>> ", tagArray);
            for (let i = 0; i < tagArray.length; i++) {
              console.log(tagArray[i]);
              this.tag.push(tagArray[i]);
            }
          }
        }
      });
      this.dialogE = true;
    },
    refresh() {
      this.$emit("loaddata", "loaddata");
    },
  },
};
</script>